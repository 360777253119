
$borderW: 2px;

.blob-btn {
  $numOfBlobs: 4;
  z-index: 1;
  position: relative;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s 0.2s;
  }
  
  &:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: $borderW*1.5;
    top: $borderW*1.5;
    width: 100%;
    height: 100%;

    transition: all 0.3s 0.2s;
  }
  
  &:hover {
    &:after {
      transition: all 0.3s;
      left: 0;
      top: 0;
    }
  }
  
  &__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  &__blobs {
    position: relative;
    display: block;
    height: 120%;
    filter: url('#goo');
  }
  
  &__blob {
    position: absolute;
    top: -12%;
    width: 100% / $numOfBlobs;
    height: 115%;
    border-radius: 100px;
    transform: translate3d(0,150%,0) scale(1.7);
    transition: transform 0.6s;
    
    @supports(filter: url('#goo')) {
      transform: translate3d(0,150%,0) scale(1.4);
    }
    
    @for $i from 1 through $numOfBlobs {
      &:nth-child(#{$i}) {
        left: ($i - 1) * (120% / $numOfBlobs);
        transition-delay: ($i - 1) * 0.08s;
      }
    }
    
    .blob-btn:hover & {
      transform: translateZ(0) scale(1.7);
      
      @supports(filter: url('#goo')) {
        transform: translateZ(0) scale(1.4);
      }
    }
  }
  
}