
a {
  text-decoration: none;

}
span.bold {
  font-weight: 500;
}
span.bolder {
  font-weight: 700;
}
span.f3 {
  font-family: 'Permanent Marker', cursive;
}

.d-block-mobile {
  display: none;
}
@media only screen and (max-width: 800px) {
  .d-block-mobile {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .d-none-mobile {
    display: none;
  }
}
